import { defineStore } from 'pinia'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Category, Store } from '~/composables/useMenuModel'

export const useDomainCategoriesStore = defineStore('domain_categories', () => {
  const categories = ref<Category[]>([])

  const getCategories = computed(() => categories.value)
  const categoryStyle = computed(() => {
    return useCategoryStyle()
  })
  const getCategoriesByCategoryStyle = computed(() => {
    let categoriesTemp = [...categories.value]
    if (categoryStyle.value) {
      categoriesTemp =
        categoriesTemp?.reduce((total, category: Category) => {
          if (category.children_count === 0) {
            total.push(category)
          } else {
            total.push(...(category.children ?? []))
          }
          return total
        }, [] as Category[]) || []
    } else {
      return categories.value
    }
    return categoriesTemp
  })
  async function fetchCategories(store: Store) {
    const { categoryModel } = useMenuModel()
    const currentBranchId = useBranchId()
    const { data } = await useBasicFetch(
      getAllRoutes().categoriesRoutes.categories,
      {
        query: {
          store_id: store.id,
          store_type_id: store.type_id,
          branch_id: currentBranchId,
          limit: 100,
          with_children: true,
          children_count: true
        },
        transform: (data: SuccessResponse<Category[]>) =>
          data.data.map(categoryModel)
      }
    )
    categories.value = data.value || []
  }

  return {
    categories: getCategories,
    categoriesByCategoryStyle: getCategoriesByCategoryStyle,
    fetchCategories
  }
})
